import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./screens/HomePage";
import DoubtSolving from "./screens/DoubtSolving";
import OnlineTutoring from "./screens/OnlineTutoring";
import HomeTutor from "./screens/HomeTutor";
import ExamSeries from "./screens/ExamSeries";
import Resource from "./screens/Resource";
import AiScreen from "./screens/AiScreen";
import { ToastContainer } from "react-toastify";
import Error from "./screens/Error";
import Exam from "./screens/Exam";
import Chapters from "./components/Resource/Chapters";
import QuestionList from "./components/Resource/QuestionsList";
import ContactUs from "./screens/ContactUs";
import About from "./screens/About";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TnC from "./screens/TnC";
import CnR from "./screens/CnR";
import SdP from "./screens/SdP";
import Blogs from "./screens/Blogs";
import AiChatScreen from "./screens/AiChatScreen";
import DeleteAccount from "./screens/DeleteAccount";
import NewQuestionList from "./components/Resource/NewQuestionList";
import { QuestionBank } from "./components/QuestionBank/questionBank";
function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="doubt-solving" element={<DoubtSolving />} />
          <Route exact path="online-tutoring" element={<OnlineTutoring />} />
          <Route exact path="home-tutoring" element={<HomeTutor />} />
          <Route exact path="exam-series" element={<ExamSeries />} />
          <Route exact path="questionBank" element={<QuestionBank />} />

          <Route exact path="/exam" element={<Exam />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="resource-hub" element={<Resource />} />
          <Route exact path="/chapter" element={<Chapters />} />
          <Route exact path="/questions" element={<NewQuestionList />} />
          <Route exact path="/intelli-ai" element={<AiScreen />} />
          <Route exact path="/intelli-ai-chat" element={<AiChatScreen />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/about-us" element={<About />} />

          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/delete-account" element={<DeleteAccount />} />
          <Route exact path="/terms-and-conditions" element={<TnC />} />
          <Route exact path="/cancellation-and-refunds" element={<CnR />} />
          <Route
            exact
            path="/shipping-and-delievery-policies"
            element={<SdP />}
          />
          <Route exact path="*" element={<Error />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}
export default App;
