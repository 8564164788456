// config.js
const baseUrls = {
  dev: {
    Url: "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/content-pannel",

    // Add more services as needed https://dev-ums.onrender.com
  },
  UAT: {
    Url: "http://localhost:4001",

    //   service1: 'https://uat.service1.example.com',
    //   service2: 'https://uat.service2.example.com',
    // Add more services as needed
  },
  prod: {
    Url: "https://13.201.99.100:443",
    //   service2: 'https://prod.service2.example.com',
    // Add more services as needed
  },
};

const currentEnvironment = "dev"; // Set this based on your configuration

export default baseUrls[currentEnvironment];

export const decodeHtmlEntities = (text) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
};
